<template>
  <div class="main-view">
    <div class="workWrap">
      <div style="margin-bottom:20px;font-size:18px;font-weight: bold;">{{province}}{{city}}{{area}}</div>
      <el-row class="total">
        <el-col :span="6">
          <div class="name">机构总数（家）</div>
          <div class="num">123</div>
          <div class="hint">本地区内认证的机构数量</div>
        </el-col>
        <el-col :span="6">
          <div class="name">产品总数（个）</div>
          <div class="num">362</div>
          <div class="hint">本地区内上架的产品总数</div>
        </el-col>
        <el-col :span="6">
          <div class="name">学校总数 (所)</div>
          <div class="num">53</div>
          <div class="hint">本地区内入驻的学校数量</div>
        </el-col>
        <el-col :span="6">
          <div class="name">师生总数（人）</div>
          <div class="num">5,326</div>
          <div class="hint">本地区内入驻学校的教师及学生总数</div>
        </el-col>
      </el-row>

      <el-row class="mt20">
        <el-col :span="14" >
          <el-row :gutter="20">
            <el-col :span="12" class="flex mt30">
              <div>
                <div>订单数量（笔）</div>
                <div class="num2">894</div>
              </div>
              <img class="chart" :src="require('@/assets/img/home/chart1.png')">
            </el-col>
            <el-col :span="12" class="flex mt30">
              <div>
                <div>订单数量（笔）</div>
                <div class="num2">894</div>
              </div>
              <img class="chart" :src="require('@/assets/img/home/chart2.png')">
            </el-col>
            <el-col :span="12" class="flex mt30">
              <div>
                <div>订单数量（笔）</div>
                <div class="num2">894</div>
              </div>
              <img class="chart" :src="require('@/assets/img/home/chart3.png')">
            </el-col>
            <el-col :span="12" class="flex mt30">
              <div>
                <div>订单数量（笔）</div>
                <div class="num2">894</div>
              </div>
              <img class="chart" :src="require('@/assets/img/home/chart4.png')">
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-date-picker
            v-model="selTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择时间范围，默认近7天" 
            size="mini"
            style="width:240px;"
          >
          </el-date-picker>
          <Echart :options="options1" height="300px" width="100%"></Echart>
        </el-col>
      </el-row>
      
      <el-row>
        <el-col :span="7">
          <Echart :options="options2" height="350px" width="100%"></Echart>
        </el-col>
        <el-col :span="5">
          <Echart :options="options3" height="350px" width="100%"></Echart>
        </el-col>
        <el-col :span="5">
          <Echart :options="options4" height="350px" width="100%"></Echart>
        </el-col>
        <el-col :span="7">
          <Echart :options="options5" height="350px" width="100%"></Echart>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
import { getStorage } from '@/storage'
import Echart from "@/components/echart/index.vue";
// import * as $echarts from "echarts";
export default {
  name: 'Home',
  components:{
    Echart,
  },
  data() {
    return {
      province: '',
      city: '',
      area: '',
      selTime: [],
      start_time: '',
      end_time: '',

      options1: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid:{
          left:'3%',
          right:'4%',
          top:'20%',
          bottom:'20%',
          containLabel:true
        },
        legend: {
          right: '5%',
          data: ['订单数', '浏览量']
        },
        xAxis: [
          {
            type: 'category',
            data: ['6-1', '6-2', '6-3', '6-4', '6-5', '6-6', '6-7'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: '{value}',
              color:"#262626"
            },
          axisLine: {
              show: true, //隐藏X轴轴线
              lineStyle: {
                color: "#CECECE",
                width: 1,
              },
          },
              
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              formatter: ''
            }
          }
        ],
        series: [
          {
            name: '订单数',
            type: 'bar',
            barWidth: '20',
            color:'#9BD3FF',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml';
              }
            },
            data: [
              20, 49, 70, 112, 216, 176.7, 135.6, 162.2, 132.6, 210.0, 86.4, 93.3
            ]
          },
        
          {
            name: '浏览量',
            type: 'line',
            yAxisIndex: 1,
            color:'#FFD34F',
            //圆圈大小
            symbolSize: 10,
            
            showAllSymbol: true,
              lineStyle: {
                color: "#FFD34F"
              },
              showSymbol: true,
              symbol: 'circle',
              itemStyle: {
                normal: {
                  color: "#fff",
                  borderColor:'#CCCCCC',
                  borderWidth:3
                }
                
              },
            
            
            
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C';
              }
            },
            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
          }
        ]
      },
      options2: {
        legend: { show: false },
        title: {
          text: '报名统计',
          x: '32%',
          y: '40%',
          textStyle: {
            fontSize: 18,
            color:'#3A3333'
          }
        },
        series: [
          {
            name: '报名统计',
            type: 'pie',
            center: ['45%', '50%'],
            radius: ['30%', '50%'],
            top: '-50px',
            avoidLabelOverlap: true,
            itemStyle: { borderColor: '#fff', borderWidth: 2 },
            color: ['#00B4FF','#FEBB06','#FF532B'],
            label: {
              formatter: function (e) {
                let {
                  data: { value, name, percent },
                } = e;
                return `{x|}{a|${name}}\n{b|${value}人,}{c|${percent}}`;
              },
              minMargin: 5,
              lineHeight: 15,
              rich: {
                x: { width: 10, height: 10, backgroundColor: 'inherit', borderRadius: 5 },
                a: { fontSize: 14, color: 'inherit', padding: [0, 20, 0, 8] },
                b: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 18] },
                c: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 8] },
              },
            },
            data: [
              { value: 68, name: '已支付', percent: '47.46%' },
              { value: 28, name: '待支付', percent: '30.51%' },
              { value: 31, name: '已退款', percent: '1.69%' },
            ],
          },
        ],
      },
      options3: {
        title: {
          text: '订单统计',
          x: '36%',
          y: '40%',
          textStyle: {
            fontSize: 18,
            color:'#3A3333'
          }
        },
        tooltip: {
          trigger: 'item',
          triggerOn:'click',
          formatter:'{b}: {c}人 ({d}%)'
        },
        legend: {
          orient: "vertical",
          bottom: '0',
          right: 40,
          left: '25%',
          itemGap: 12,
          itemWidth: 8,
          itemHeight: 6,
          // 使用回调函数
          formatter: function (name) {
            var data = [{name:'待支付',value:25},{name:'待核销',value:30},{name:'已完成',value:25},{name:'已取消',value:18},{name:'已退款',value:8}];
            var total = 0;
            var tarValue;
            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            var p = ((tarValue / total) * 100).toFixed(2);
            return '{a|'+name+'}' + '  {b|￥' +tarValue + " }" + " （" + p + "%）";
          },
          textStyle: {
            fontSize: 12,
            color: '#999',
            rich: {
              a: {
                color: '#666',
                width: 40
              },
              b: {
                width: 50
              }
            }
          }
        },
        color: ["#FFA84C","#8065F6","#50C1F6","#FFDD68","#FF7A8C"],
        series: [
          {
            type: 'pie',
            radius: ['65%', '100%'],
            avoidLabelOverlap: false,
            top: '-50px',
            left: 'center',
            label: {
              show: false,
              position: 'center'
            },
            data:  [{name:'待支付',value:25},{name:'待核销',value:30},{name:'已完成',value:25},{name:'已取消',value:18},{name:'已退款',value:8}]
          }
        ]
      },
      options4: {
        title: {
          text: '金额统计',
          x: '36%',
          y: '40%',
          textStyle: {
            fontSize: 18,
            color:'#3A3333'
          }
        },
        tooltip: {
          trigger: 'item',
          triggerOn:'click',
          formatter:'{b}: {c}人 ({d}%)'
        },
        legend: {
          orient: "vertical",
          bottom: '-5px',
          right: 40,
          left: '25%',
          itemGap: 12,
          itemWidth: 8,
          itemHeight: 6,
          // 使用回调函数
          formatter: function (name) {
            var data = [{name:'待支付',value:25},{name:'待核销',value:30},{name:'已完成',value:25},{name:'已退款',value:8}];
            var total = 0;
            var tarValue;
            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            var p = ((tarValue / total) * 100).toFixed(2);
            return '{a|'+name+'}' + '  {b|￥' +tarValue + " }" + " （" + p + "%）";
          },
          textStyle: {
            fontSize: 12,
            color: '#999',
            rich: {
              a: {
                color: '#666',
                width: 40
              },
              b: {
                width: 50
              }
            }
          }
        },
        color: ["#FFA84C","#8065F6","#50C1F6","#FF7A8C"],
        series: [
          {
            type: 'pie',
            radius: ['65%', '100%'],
            avoidLabelOverlap: false,
            top: '-50px',
            left: 'center',
            label: {
              show: false,
              position: 'center'
            },
            data:  [{name:'待支付',value:25},{name:'待核销',value:30},{name:'已完成',value:25},{name:'已退款',value:8}]
          }
        ]
      },
      options5: {
        legend: { show: false },
        title: {
          text: '提现统计',
          x: '32%',
          y: '40%',
          textStyle: {
            fontSize: 18,
            color:'#3A3333'
          }
        },
        series: [
          {
            name: '提现统计',
            type: 'pie',
            center: ['45%', '50%'],
            radius: ['30%', '50%'],
            top: '-50px',
            avoidLabelOverlap: true,
            itemStyle: { borderColor: '#fff', borderWidth: 2 },
            color: ['#FEBB06','#50DAAB','#50C1F6'],
            label: {
              formatter: function (e) {
                let {
                  data: { value, name, percent },
                } = e;
                return `{x|}{a|${name}}\n{b|￥${value},}{c|${percent}}`;
              },
              minMargin: 5,
              lineHeight: 15,
              rich: {
                x: { width: 10, height: 10, backgroundColor: 'inherit', borderRadius: 5 },
                a: { fontSize: 14, color: 'inherit', padding: [0, 20, 0, 8] },
                b: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 18] },
                c: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 8] },
              },
            },
            data: [
              { value: 38, name: '待提现', percent: '30.51%' },
              { value: 68, name: '已提现', percent: '47.46%' },
              { value: 31, name: '提现中', percent: '1.69%' },
            ],
          },
        ],
      }

    }
  },

  created() {
    this.province = getStorage('province');
    this.city = getStorage('city');
    this.area = getStorage('area');
  },
  methods: {
   
  }
}
</script>

<style scoped lang="scss">
.workWrap {
  background: #fff;
  height: 100%;
  overflow-y: scroll;
  padding: 30px;
}
.total {
  background: #F7FAFF;
  padding: 24px 30px;
  border-radius: 6px;
  color: #2E3439;
}
.name {
  font-size: 18px;
}
.num {
  font-size: 32px;
  font-weight: bold;
  margin: 10px 0;
}
.hint {
  color: #666666;
  font-size: 12px;
}
.num2 {
  font-size: 28px;
  font-weight: bold;
}
.chart {
  width: 222px;
  height: 82px;
}

.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.chart1 {
  width: 100px;
  height: 400px;
}
</style>